import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {
  catchError,
  Observable,
  Subject,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);
  private router = inject(Router);

  private refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject<boolean>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = this.applyToken(request);
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleResponseError(err, request, next);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applyToken(request: HttpRequest<any>) {
    const token: string | null = localStorage.getItem('accessToken');
    if (token) {
      return request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
    }
    return request;
  }

  private handleResponseError(
    error: HttpErrorResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (error.status === 401) {
      if (
        request.url.indexOf('logout') != -1 ||
        request.url.indexOf('refreshauthentication') != -1
      ) {
        this.authService.removeTokens();
        this.router.navigate(['/login']);
        return throwError(() => error);
      }

      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.applyToken(request);
          return next.handle(request);
        }),
        catchError(() => {
          this.logout();
          return throwError(() => error);
        })
      );
    }

    return throwError(() => error);
  }

  private refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next(true);
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      return this.authService.refreshToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(true);
        }),
        catchError(() => {
          this.refreshTokenInProgress = false;
          this.authService.logout();
          return throwError(() => new Error('Error refreshing token'));
        })
      );
    }
  }

  private logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
