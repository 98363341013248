import { FileCitation } from './citation';
import { FileReference } from './download-file';

export class ChatMessage {
  constructor(
    public text: string,
    public type: ChatMessageType,
    public index: number,
    public citations: FileCitation[],
    public files: FileReference[]
  ) {}
}

export enum ChatMessageType {
  User,
  Bot
}
