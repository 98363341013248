import { inject, Injectable, OnDestroy } from '@angular/core';
import { SignalrService } from '../app/shared/signalr.service';
import { Subscription } from 'rxjs';
import {
  ChunkReceived,
  CitationReceived,
  FileReady
} from '../app/chat/chat.actions';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class ChatService implements OnDestroy {
  private readonly subs = new Subscription();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  signalRService = inject(SignalrService);
  store = inject(Store);
  initialize(): void {
    this.subs.add(
      this.signalRService.register<any>('GptData').subscribe((data) => {
        if (Array.isArray(data)) {
          data.forEach((d) => this.processMessage(d));
        } else {
          this.processMessage(data);
        }
      })
    );
  }

  processMessage(data: any) {
    if (data.type == 'ChunkUpdate' || data.type == 'ChunkUpdateDone') {
      this.store.dispatch(
        new ChunkReceived(data.chunkIndex, data.text, data.isSuccess || false)
      );
    } else if (data.type == 'CitationUpdate') {
      this.store.dispatch(
        new CitationReceived(
          data.sourceName,
          data.sourceId,
          data.containerName,
          data.containerId
        )
      );
    } else if (data.type == 'FileReady') {
      this.store.dispatch(new FileReady(data.fileName, data.url));
    }
  }
}
