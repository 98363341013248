import {
  Component,
  effect,
  EffectRef,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { FocusTrapModule } from 'primeng/focustrap';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ChatMessage, ChatMessageType } from './models/chat-message';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationItem } from './models/navigation-item';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    ButtonDirective,
    InputTextareaModule,
    InputTextModule,
    ReactiveFormsModule,
    FocusTrapModule,
    NgForOf,
    NgClass,
    NgIf
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, OnDestroy {
  fb = inject(NonNullableFormBuilder);
  domSanitizer = inject(DomSanitizer);

  formGroup: FormGroup<ChatMessageForm>;

  @ViewChild('searchInput') searchInput!: ElementRef;
  @Input() onShow!: EventEmitter<void>;
  @Input() newThread!: EventEmitter<void>;

  messages = input.required<ChatMessage[]>();
  assetName = input.required<string | null>();
  receivingChunks = input.required<boolean>();
  @Output() query = new EventEmitter<string>();

  @Output() sourceNavigation = new EventEmitter<NavigationItem>();
  @Output() containerNavigation = new EventEmitter<string>();

  @ViewChild('messagesElm') messagesElm!: ElementRef;
  private scrollEffect: EffectRef;

  constructor() {
    this.formGroup = new FormGroup<ChatMessageForm>({
      message: this.fb.control('', [Validators.required])
    });

    this.scrollEffect = effect(() => {
      this.messages();
      this.messagesElm.nativeElement.scrollTop =
        this.messagesElm.nativeElement.scrollHeight;
    });
  }
  ngOnDestroy(): void {
    this.onShow?.unsubscribe();
    this.scrollEffect.destroy();
  }

  ngOnInit(): void {
    this.newThread?.subscribe(() => {
      this.resetView();
    });
    this.onShow?.subscribe(() => {
      this.resetView();
    });
  }

  private resetView() {
    this.searchInput.nativeElement.focus();
    this.messagesElm.nativeElement.scrollTop =
      this.messagesElm.nativeElement.scrollHeight;
  }

  onSubmit(_: SubmitEvent) {
    this.query.emit(this.formGroup.value.message);
    this.formGroup.reset();

    window.setTimeout(() => {
      this.messagesElm.nativeElement.scrollTop =
        this.messagesElm.nativeElement.scrollHeight;
    }, 100);
  }

  protected readonly ChatMessageType = ChatMessageType;

  trackByMessages(_: number, item: ChatMessage) {
    return item.index;
  }

  navigateToSource(containerId: string, sourceId: string) {
    this.sourceNavigation.emit({ containerId, sourceId });
  }

  navigateToContainer(containerId: string) {
    this.containerNavigation.emit(containerId);
  }
}

type ChatMessageForm = {
  message: FormControl<string>;
};
